// Generated by ReScript, PLEASE EDIT WITH CARE

import * as T from "../T.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as LinkText from "./shareable/LinkText.bs.js";
import * as NextIntl from "next-intl";
import * as ReactNative from "react-native";
import * as ViewContained from "./shareable/ViewContained.bs.js";
import * as Font$ReactMultiversal from "react-multiversal/src/Font.bs.js";
import * as Predefined$ReactMultiversal from "react-multiversal/src/Predefined.bs.js";
import * as SpacedView$ReactMultiversal from "react-multiversal/src/SpacedView.bs.js";

function WebsiteFooter(Props) {
  var theme = Curry._2(T.useTheme, undefined, undefined);
  var t = NextIntl.useTranslations();
  return React.createElement(ReactNative.View, {
              style: [theme.styles.back],
              children: React.createElement(ViewContained.make, {
                    children: React.createElement(SpacedView$ReactMultiversal.make, {
                          vertical: /* S */4,
                          horizontal: /* S */4,
                          style: Predefined$ReactMultiversal.styles.rowSpaceBetween,
                          children: null
                        }, React.createElement(ReactNative.Text, {
                              children: "© 2022 Jokerie",
                              style: [
                                Font$ReactMultiversal.ios.caption,
                                theme.styles.textLight1
                              ]
                            }), React.createElement(LinkText.make, {
                              children: t("Place-add"),
                              href: "/place/add",
                              style: [
                                Font$ReactMultiversal.ios.caption,
                                theme.styles.textLight1
                              ]
                            }), React.createElement(LinkText.make, {
                              children: "Made in France",
                              href: "https://moox.io",
                              style: [
                                Font$ReactMultiversal.ios.caption,
                                theme.styles.textLight1
                              ]
                            }))
                  })
            });
}

var make = WebsiteFooter;

var $$default = WebsiteFooter;

export {
  make ,
  $$default ,
  $$default as default,
}
/* T Not a pure module */
