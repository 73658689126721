// Generated by ReScript, PLEASE EDIT WITH CARE


function url(s) {
  if (s.startsWith("/")) {
    return process.env.NEXT_PUBLIC_ENDPOINT + s;
  } else {
    return s;
  }
}

export {
  url ,
}
/* No side effect */
