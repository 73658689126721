// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";

var make = React.forwardRef(function (Props, ref_) {
      var underlineOpt = Props.underline;
      var underlineOnFocusOpt = Props.underlineOnFocus;
      var styl = Props.style;
      var accessibilityActions = Props.accessibilityActions;
      var accessibilityHint = Props.accessibilityHint;
      var accessibilityLabel = Props.accessibilityLabel;
      var accessibilityRole = Props.accessibilityRole;
      var accessibilityState = Props.accessibilityState;
      var accessible = Props.accessible;
      var adjustsFontSizeToFit = Props.adjustsFontSizeToFit;
      var allowFontScaling = Props.allowFontScaling;
      var android_hyphenationFrequency = Props.android_hyphenationFrequency;
      var ariaLevel = Props.ariaLevel;
      var children = Props.children;
      var dataDetectorTypes = Props.dataDetectorTypes;
      var disabled = Props.disabled;
      var ellipsizeMode = Props.ellipsizeMode;
      var maxFontSizeMultiplier = Props.maxFontSizeMultiplier;
      var minimumFontScale = Props.minimumFontScale;
      var nativeID = Props.nativeID;
      var numberOfLines = Props.numberOfLines;
      var onAccessibilityAction = Props.onAccessibilityAction;
      var onLayout = Props.onLayout;
      var onLongPress = Props.onLongPress;
      var onPress = Props.onPress;
      var onPressIn = Props.onPressIn;
      var onPressOut = Props.onPressOut;
      var onTextLayout = Props.onTextLayout;
      var pressRetentionOffset = Props.pressRetentionOffset;
      var selectable = Props.selectable;
      var selectionColor = Props.selectionColor;
      var suppressHighlighting = Props.suppressHighlighting;
      var testID = Props.testID;
      var textBreakStrategy = Props.textBreakStrategy;
      var value = Props.value;
      var onMoveShouldSetResponder = Props.onMoveShouldSetResponder;
      var onMoveShouldSetResponderCapture = Props.onMoveShouldSetResponderCapture;
      var onResponderEnd = Props.onResponderEnd;
      var onResponderGrant = Props.onResponderGrant;
      var onResponderMove = Props.onResponderMove;
      var onResponderReject = Props.onResponderReject;
      var onResponderRelease = Props.onResponderRelease;
      var onResponderStart = Props.onResponderStart;
      var onResponderTerminate = Props.onResponderTerminate;
      var onResponderTerminationRequest = Props.onResponderTerminationRequest;
      var onStartShouldSetResponder = Props.onStartShouldSetResponder;
      var onStartShouldSetResponderCapture = Props.onStartShouldSetResponderCapture;
      var href = Props.href;
      var hrefAttrs = Props.hrefAttrs;
      var onClick = Props.onClick;
      var onClickCapture = Props.onClickCapture;
      var onContextMenu = Props.onContextMenu;
      var onFocus = Props.onFocus;
      var onBlur = Props.onBlur;
      var onKeyDown = Props.onKeyDown;
      var onKeyDownCapture = Props.onKeyDownCapture;
      var onKeyUp = Props.onKeyUp;
      var onKeyUpCapture = Props.onKeyUpCapture;
      var onMouseDown = Props.onMouseDown;
      var onMouseEnter = Props.onMouseEnter;
      var onMouseLeave = Props.onMouseLeave;
      var onMouseMove = Props.onMouseMove;
      var onMouseOut = Props.onMouseOut;
      var onMouseOver = Props.onMouseOver;
      var onMouseUp = Props.onMouseUp;
      var underline = underlineOpt !== undefined ? underlineOpt : false;
      var underlineOnFocus = underlineOnFocusOpt !== undefined ? underlineOnFocusOpt : false;
      var match = React.useState(function () {
            return false;
          });
      var hasMouseFocus_set = match[1];
      var handleMouseEnter = React.useCallback((function (param) {
              Belt_Option.map(onMouseEnter, (function (cb, __x) {
                      Curry._1(cb, __x);
                    }));
              Curry._1(hasMouseFocus_set, (function (param) {
                      return true;
                    }));
            }), [
            onMouseEnter,
            hasMouseFocus_set
          ]);
      var handleMouseLeave = React.useCallback((function (param) {
              Belt_Option.map(onMouseLeave, (function (cb, __x) {
                      Curry._1(cb, __x);
                    }));
              Curry._1(hasMouseFocus_set, (function (param) {
                      return false;
                    }));
            }), [
            onMouseLeave,
            hasMouseFocus_set
          ]);
      var match$1 = React.useState(function () {
            return false;
          });
      var hasFocus_set = match$1[1];
      var handleFocus = React.useCallback((function (param) {
              Belt_Option.map(onFocus, (function (cb, __x) {
                      Curry._1(cb, __x);
                    }));
              Curry._1(hasFocus_set, (function (param) {
                      return true;
                    }));
            }), [
            onFocus,
            hasFocus_set
          ]);
      var handleBlur = React.useCallback((function (param) {
              Belt_Option.map(onBlur, (function (cb, __x) {
                      Curry._1(cb, __x);
                    }));
              Curry._1(hasFocus_set, (function (param) {
                      return false;
                    }));
            }), [
            onBlur,
            hasFocus_set
          ]);
      var style = [
        {
          textDecorationLine: underline || underlineOnFocus && (match$1[0] || match[0]) ? "underline" : "none"
        },
        styl
      ];
      var tmp = {
        style: style,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave
      };
      var tmp$1 = Belt_Option.map((ref_ == null) ? undefined : Caml_option.some(ref_), (function (prim) {
              return prim;
            }));
      if (tmp$1 !== undefined) {
        tmp.ref = Caml_option.valFromOption(tmp$1);
      }
      if (accessibilityActions !== undefined) {
        tmp.accessibilityActions = Caml_option.valFromOption(accessibilityActions);
      }
      if (accessibilityHint !== undefined) {
        tmp.accessibilityHint = accessibilityHint;
      }
      if (accessibilityLabel !== undefined) {
        tmp.accessibilityLabel = accessibilityLabel;
      }
      if (accessibilityRole !== undefined) {
        tmp.accessibilityRole = Caml_option.valFromOption(accessibilityRole);
      }
      if (accessibilityState !== undefined) {
        tmp.accessibilityState = Caml_option.valFromOption(accessibilityState);
      }
      if (accessible !== undefined) {
        tmp.accessible = accessible;
      }
      if (adjustsFontSizeToFit !== undefined) {
        tmp.adjustsFontSizeToFit = adjustsFontSizeToFit;
      }
      if (allowFontScaling !== undefined) {
        tmp.allowFontScaling = allowFontScaling;
      }
      if (android_hyphenationFrequency !== undefined) {
        tmp.android_hyphenationFrequency = Caml_option.valFromOption(android_hyphenationFrequency);
      }
      if (ariaLevel !== undefined) {
        tmp.ariaLevel = ariaLevel;
      }
      if (children !== undefined) {
        tmp.children = Caml_option.valFromOption(children);
      }
      if (dataDetectorTypes !== undefined) {
        tmp.dataDetectorTypes = Caml_option.valFromOption(dataDetectorTypes);
      }
      if (disabled !== undefined) {
        tmp.disabled = disabled;
      }
      if (ellipsizeMode !== undefined) {
        tmp.ellipsizeMode = Caml_option.valFromOption(ellipsizeMode);
      }
      if (maxFontSizeMultiplier !== undefined) {
        tmp.maxFontSizeMultiplier = maxFontSizeMultiplier;
      }
      if (minimumFontScale !== undefined) {
        tmp.minimumFontScale = minimumFontScale;
      }
      if (nativeID !== undefined) {
        tmp.nativeID = nativeID;
      }
      if (numberOfLines !== undefined) {
        tmp.numberOfLines = numberOfLines;
      }
      if (onAccessibilityAction !== undefined) {
        tmp.onAccessibilityAction = Caml_option.valFromOption(onAccessibilityAction);
      }
      if (onLayout !== undefined) {
        tmp.onLayout = Caml_option.valFromOption(onLayout);
      }
      if (onLongPress !== undefined) {
        tmp.onLongPress = Caml_option.valFromOption(onLongPress);
      }
      if (onPress !== undefined) {
        tmp.onPress = Caml_option.valFromOption(onPress);
      }
      if (onPressIn !== undefined) {
        tmp.onPressIn = Caml_option.valFromOption(onPressIn);
      }
      if (onPressOut !== undefined) {
        tmp.onPressOut = Caml_option.valFromOption(onPressOut);
      }
      if (onTextLayout !== undefined) {
        tmp.onTextLayout = Caml_option.valFromOption(onTextLayout);
      }
      if (pressRetentionOffset !== undefined) {
        tmp.pressRetentionOffset = Caml_option.valFromOption(pressRetentionOffset);
      }
      if (selectable !== undefined) {
        tmp.selectable = selectable;
      }
      if (selectionColor !== undefined) {
        tmp.selectionColor = selectionColor;
      }
      if (suppressHighlighting !== undefined) {
        tmp.suppressHighlighting = suppressHighlighting;
      }
      if (testID !== undefined) {
        tmp.testID = testID;
      }
      if (textBreakStrategy !== undefined) {
        tmp.textBreakStrategy = Caml_option.valFromOption(textBreakStrategy);
      }
      if (value !== undefined) {
        tmp.value = value;
      }
      if (onMoveShouldSetResponder !== undefined) {
        tmp.onMoveShouldSetResponder = Caml_option.valFromOption(onMoveShouldSetResponder);
      }
      if (onMoveShouldSetResponderCapture !== undefined) {
        tmp.onMoveShouldSetResponderCapture = Caml_option.valFromOption(onMoveShouldSetResponderCapture);
      }
      if (onResponderEnd !== undefined) {
        tmp.onResponderEnd = Caml_option.valFromOption(onResponderEnd);
      }
      if (onResponderGrant !== undefined) {
        tmp.onResponderGrant = Caml_option.valFromOption(onResponderGrant);
      }
      if (onResponderMove !== undefined) {
        tmp.onResponderMove = Caml_option.valFromOption(onResponderMove);
      }
      if (onResponderReject !== undefined) {
        tmp.onResponderReject = Caml_option.valFromOption(onResponderReject);
      }
      if (onResponderRelease !== undefined) {
        tmp.onResponderRelease = Caml_option.valFromOption(onResponderRelease);
      }
      if (onResponderStart !== undefined) {
        tmp.onResponderStart = Caml_option.valFromOption(onResponderStart);
      }
      if (onResponderTerminate !== undefined) {
        tmp.onResponderTerminate = Caml_option.valFromOption(onResponderTerminate);
      }
      if (onResponderTerminationRequest !== undefined) {
        tmp.onResponderTerminationRequest = Caml_option.valFromOption(onResponderTerminationRequest);
      }
      if (onStartShouldSetResponder !== undefined) {
        tmp.onStartShouldSetResponder = Caml_option.valFromOption(onStartShouldSetResponder);
      }
      if (onStartShouldSetResponderCapture !== undefined) {
        tmp.onStartShouldSetResponderCapture = Caml_option.valFromOption(onStartShouldSetResponderCapture);
      }
      if (href !== undefined) {
        tmp.href = href;
      }
      if (hrefAttrs !== undefined) {
        tmp.hrefAttrs = Caml_option.valFromOption(hrefAttrs);
      }
      if (onClick !== undefined) {
        tmp.onClick = Caml_option.valFromOption(onClick);
      }
      if (onClickCapture !== undefined) {
        tmp.onClickCapture = Caml_option.valFromOption(onClickCapture);
      }
      if (onContextMenu !== undefined) {
        tmp.onContextMenu = Caml_option.valFromOption(onContextMenu);
      }
      if (onKeyDown !== undefined) {
        tmp.onKeyDown = Caml_option.valFromOption(onKeyDown);
      }
      if (onKeyDownCapture !== undefined) {
        tmp.onKeyDownCapture = Caml_option.valFromOption(onKeyDownCapture);
      }
      if (onKeyUp !== undefined) {
        tmp.onKeyUp = Caml_option.valFromOption(onKeyUp);
      }
      if (onKeyUpCapture !== undefined) {
        tmp.onKeyUpCapture = Caml_option.valFromOption(onKeyUpCapture);
      }
      if (onMouseDown !== undefined) {
        tmp.onMouseDown = Caml_option.valFromOption(onMouseDown);
      }
      if (onMouseMove !== undefined) {
        tmp.onMouseMove = Caml_option.valFromOption(onMouseMove);
      }
      if (onMouseOut !== undefined) {
        tmp.onMouseOut = Caml_option.valFromOption(onMouseOut);
      }
      if (onMouseOver !== undefined) {
        tmp.onMouseOver = Caml_option.valFromOption(onMouseOver);
      }
      if (onMouseUp !== undefined) {
        tmp.onMouseUp = Caml_option.valFromOption(onMouseUp);
      }
      return React.createElement(ReactNative.Text, tmp);
    });

export {
  make ,
}
/* make Not a pure module */
