// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../User.bs.js";
import * as React from "react";
import * as MapKitJs from "../bindings/MapKitJs.bs.js";
import Head from "next/head";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import Script from "next/script";
import * as ReactNative from "react-native";
import * as Animated$ReactNative from "rescript-react-native/src/apis/Animated.bs.js";
import * as Predefined$ReactMultiversal from "react-multiversal/src/Predefined.bs.js";
import * as ReactNativeSafeAreaContext from "react-native-safe-area-context";

var scrollYAnimatedValue = new (ReactNative.Animated.Value)(0);

var requested = {
  contents: false
};

if (Predefined$ReactMultiversal.isClient) {
  var tick = function (param) {
    Animated$ReactNative.start(Animated$ReactNative.spring(scrollYAnimatedValue, {
              toValue: window.scrollY,
              useNativeDriver: true
            }), undefined, undefined);
  };
  window.addEventListener("scroll", (function (param) {
          if (!requested.contents) {
            requested.contents = true;
            requestAnimationFrame(function (param) {
                  tick(undefined);
                  requested.contents = false;
                });
            return ;
          }
          
        }));
}

var initialMetrics = {
  insets: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  frame: {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }
};

function WebsiteWrapper$UserEffect(Props) {
  var userQueryRef = Props.userQueryRef;
  User.useRedirectNewUser(userQueryRef);
  return null;
}

var UserEffect = {
  make: WebsiteWrapper$UserEffect
};

var analyticsUrl = process.env.NEXT_PUBLIC_ANALYTICS_url;

function WebsiteWrapper(Props) {
  var userOptionalQueryRef = Props.userOptionalQueryRef;
  var children = Props.children;
  return React.createElement(React.Fragment, undefined, React.createElement(Head, {
                  children: null
                }, React.createElement("meta", {
                      charSet: "utf-8"
                    }), React.createElement("meta", {
                      content: "width=device-width, initial-scale=1",
                      name: "viewport"
                    }), React.createElement("style", {
                      dangerouslySetInnerHTML: {
                        __html: "@-ms-viewport { width: device-width } @viewport { width: device-width }"
                      }
                    }), React.createElement("link", {
                      href: analyticsUrl,
                      rel: "dns-prefetch"
                    }), React.createElement("link", {
                      href: analyticsUrl,
                      rel: "preconnect"
                    }), React.createElement("link", {
                      href: "/apple-touch-icon.png",
                      rel: "apple-touch-icon",
                      sizes: "180x180"
                    }), React.createElement("link", {
                      href: "/favicon-32x32.png",
                      rel: "icon",
                      sizes: "32x32",
                      type: "image/png"
                    }), React.createElement("link", {
                      href: "/favicon-16x16.png",
                      rel: "icon",
                      sizes: "16x16",
                      type: "image/png"
                    }), React.createElement("link", {
                      href: "/site.webmanifest",
                      rel: "manifest"
                    }), React.createElement("meta", {
                      content: "#ffffff",
                      name: "msapplication-TileColor"
                    }), React.createElement("meta", {
                      content: "#ffffff",
                      name: "theme-color"
                    })), React.createElement(Script, {
                  src: MapKitJs.scriptUrl,
                  strategy: "beforeInteractive"
                }), React.createElement(ReactNativeSafeAreaContext.SafeAreaProvider, {
                  initialMetrics: initialMetrics,
                  children: null
                }, userOptionalQueryRef !== undefined ? React.createElement(WebsiteWrapper$UserEffect, {
                        userQueryRef: Caml_option.valFromOption(userOptionalQueryRef)
                      }) : null, children));
}

var make = WebsiteWrapper;

export {
  scrollYAnimatedValue ,
  requested ,
  initialMetrics ,
  UserEffect ,
  analyticsUrl ,
  make ,
}
/* scrollYAnimatedValue Not a pure module */
