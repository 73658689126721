// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";
import * as Style$ReactNative from "rescript-react-native/src/apis/Style.bs.js";

var styles = ReactNative.StyleSheet.create({
      wrapper: {
        alignItems: "center",
        flexGrow: 1,
        flexShrink: 1,
        width: Style$ReactNative.pct(100)
      },
      container: {
        flexGrow: 1,
        flexShrink: 1,
        width: Style$ReactNative.pct(100)
      }
    });

var defaultMaxWidth = {
  contents: 980
};

function ViewContained(Props) {
  var wrapperStyle = Props.style;
  var contentContainerStyle = Props.contentContainerStyle;
  var maxWOpt = Props.maxWidth;
  var pointerEvents = Props.pointerEvents;
  var children = Props.children;
  var maxW = maxWOpt !== undefined ? Caml_option.valFromOption(maxWOpt) : defaultMaxWidth.contents;
  var tmp = {
    style: [
      Caml_option.some(styles.wrapper),
      wrapperStyle
    ],
    children: React.createElement(ReactNative.View, {
          style: [
            Caml_option.some(styles.container),
            {
              maxWidth: maxW
            },
            contentContainerStyle
          ],
          children: children
        })
  };
  if (pointerEvents !== undefined) {
    tmp.pointerEvents = Caml_option.valFromOption(pointerEvents);
  }
  return React.createElement(ReactNative.View, tmp);
}

var make = ViewContained;

export {
  styles ,
  defaultMaxWidth ,
  make ,
}
/* styles Not a pure module */
