// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";

function Line(Props) {
  var widthOpt = Props.width;
  var backgroundColor = Props.color;
  var heightOpt = Props.height;
  var width = widthOpt !== undefined ? Caml_option.valFromOption(widthOpt) : 1;
  var height = heightOpt !== undefined ? Caml_option.valFromOption(heightOpt) : 1;
  return React.createElement(ReactNative.View, {
              style: {
                backgroundColor: backgroundColor,
                height: height,
                width: width
              }
            });
}

var make = Line;

export {
  make ,
}
/* react Not a pure module */
