// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Theme$ReactMultiversal from "react-multiversal/src/Theme.bs.js";
import * as Predefined$ReactMultiversal from "react-multiversal/src/Predefined.bs.js";

var init = Theme$ReactMultiversal.Default.colors.light;

var init$1 = Theme$ReactMultiversal.Default.colors.dark;

var colors_light = {
  back: init.back,
  backDark: init.backDark,
  main: "#200094",
  text: init.text,
  textLight1: init.textLight1,
  textLight2: init.textLight2,
  textOnDarkLight: init.textOnDarkLight,
  textOnMain: init.textOnMain
};

var colors_dark = {
  back: init$1.back,
  backDark: init$1.backDark,
  main: Predefined$ReactMultiversal.Colors.Ios.dark.indigo,
  text: init$1.text,
  textLight1: init$1.textLight1,
  textLight2: init$1.textLight2,
  textOnDarkLight: init$1.textOnDarkLight,
  textOnMain: init$1.textOnMain
};

var colors = {
  light: colors_light,
  dark: colors_dark
};

var Themes = {
  colors: colors
};

var include = Theme$ReactMultiversal.MakeTheme(Themes);

var colors$1 = include.colors;

var webCss = include.webCss;

var colorsLight = include.colorsLight;

var colorsDark = include.colorsDark;

var colorsDynamic = include.colorsDynamic;

var stylesDynamic = include.stylesDynamic;

var stylesLight = include.stylesLight;

var stylesDark = include.stylesDark;

var themeLight = include.themeLight;

var themeDark = include.themeDark;

var useTheme = include.useTheme;

var getThemeStyleSheet = include.getThemeStyleSheet;

var getThemeHtmlId = include.getThemeHtmlId;

export {
  Themes ,
  colors$1 as colors,
  webCss ,
  colorsLight ,
  colorsDark ,
  colorsDynamic ,
  stylesDynamic ,
  stylesLight ,
  stylesDark ,
  themeLight ,
  themeDark ,
  useTheme ,
  getThemeStyleSheet ,
  getThemeHtmlId ,
}
/* include Not a pure module */
