// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import Link from "next/link";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Router from "next/router";
import * as ReactNative from "react-native";
import * as TextUnderlined from "./TextUnderlined.bs.js";

function defaultIsActive(href, router) {
  if (router.asPath === href) {
    return true;
  } else {
    return router.asPath + "/" === href;
  }
}

function LinkText(Props) {
  var accessibilityLabel = Props.accessibilityLabel;
  var activeStyle = Props.activeStyle;
  var children = Props.children;
  var href = Props.href;
  var isActiveOpt = Props.isActive;
  var numberOfLines = Props.numberOfLines;
  var styl = Props.style;
  var onPress = Props.onPress;
  var underlineOpt = Props.underline;
  var underlineOnFocusOpt = Props.underlineOnFocus;
  var isActive = isActiveOpt !== undefined ? isActiveOpt : defaultIsActive;
  var underline = underlineOpt !== undefined ? underlineOpt : false;
  var underlineOnFocus = underlineOnFocusOpt !== undefined ? underlineOnFocusOpt : false;
  var router = Router.useRouter();
  var style = [
    styl,
    Curry._2(isActive, href, router) ? activeStyle : undefined
  ];
  if (href.startsWith("/")) {
    var tmp = {
      underline: underline,
      underlineOnFocus: underlineOnFocus,
      style: style,
      accessibilityRole: "link",
      children: children,
      href: href
    };
    if (accessibilityLabel !== undefined) {
      tmp.accessibilityLabel = accessibilityLabel;
    }
    if (numberOfLines !== undefined) {
      tmp.numberOfLines = numberOfLines;
    }
    if (onPress !== undefined) {
      tmp.onPress = Caml_option.valFromOption(onPress);
    }
    return React.createElement(Link, {
                children: React.createElement(TextUnderlined.make, tmp),
                href: href
              });
  }
  var tmp$1 = {
    underline: underline,
    underlineOnFocus: underlineOnFocus,
    style: style,
    accessibilityRole: "link",
    children: children,
    onPress: (function ($$event) {
        console.log("LinkText: Linking.openURL", href);
        Belt_Option.map(onPress, (function (onPress, __x) {
                Curry._1(onPress, __x);
              }));
        ReactNative.Linking.openURL(href);
        $$event.preventDefault();
      }),
    href: href
  };
  if (accessibilityLabel !== undefined) {
    tmp$1.accessibilityLabel = accessibilityLabel;
  }
  if (numberOfLines !== undefined) {
    tmp$1.numberOfLines = numberOfLines;
  }
  return React.createElement(TextUnderlined.make, tmp$1);
}

var make = LinkText;

export {
  defaultIsActive ,
  make ,
}
/* react Not a pure module */
