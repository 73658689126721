// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Backend from "./Backend.bs.js";
import * as Js_promise from "rescript/lib/es6/js_promise.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Router from "next/router";
import * as ReactRelay from "react-relay";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as React$1 from "next-auth/react";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as User_user_graphql from "./__generated__/User_user_graphql.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as User_getById_Query_graphql from "./__generated__/User_getById_Query_graphql.bs.js";
import * as User_getLatest_Query_graphql from "./__generated__/User_getLatest_Query_graphql.bs.js";
import * as User_getByUsername_Query_graphql from "./__generated__/User_getByUsername_Query_graphql.bs.js";

var usernameDefaultPrefix = "user_";

function username(name) {
  if (name.startsWith(usernameDefaultPrefix)) {
    return "...";
  } else {
    return name;
  }
}

function use(fRef) {
  var data = ReactRelay.useFragment(User_user_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(User_user_graphql.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = ReactRelay.useFragment(User_user_graphql.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return RescriptRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return User_user_graphql.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

function readInline(fRef) {
  return User_user_graphql.Internal.convertFragment(ReactRelay.readInlineData(User_user_graphql.node, fRef));
}

var UserFragment = {
  Types: undefined,
  Operation: undefined,
  use: use,
  useOpt: useOpt,
  readInline: readInline
};

function use$1(variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  var data = ReactRelay.useLazyLoadQuery(User_getById_Query_graphql.node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(User_getById_Query_graphql.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return RescriptRelay_Internal.internal_useConvertedValue(User_getById_Query_graphql.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = ReactRelay.useQueryLoader(User_getById_Query_graphql.node);
  var loadQueryFn = match[1];
  var loadQuery = React.useMemo((function () {
          return function (param, param$1, param$2, param$3) {
            return Curry._2(loadQueryFn, User_getById_Query_graphql.Internal.convertVariables(param), {
                        fetchPolicy: param$1,
                        networkCacheConfig: param$2
                      });
          };
        }), [loadQueryFn]);
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  ReactRelay.fetchQuery(environment, User_getById_Query_graphql.node, User_getById_Query_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            Curry._1(onResult, {
                  TAG: /* Ok */0,
                  _0: User_getById_Query_graphql.Internal.convertResponse(res)
                });
          }),
        error: (function (err) {
            Curry._1(onResult, {
                  TAG: /* Error */1,
                  _0: err
                });
          })
      });
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  var __x = ReactRelay.fetchQuery(environment, User_getById_Query_graphql.node, User_getById_Query_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).toPromise();
  return Js_promise.then_((function (res) {
                return Promise.resolve(User_getById_Query_graphql.Internal.convertResponse(res));
              }), __x);
}

function usePreloaded(queryRef, param) {
  var data = ReactRelay.usePreloadedQuery(User_getById_Query_graphql.node, queryRef);
  return RescriptRelay_Internal.internal_useConvertedValue(User_getById_Query_graphql.Internal.convertResponse, data);
}

function retain(environment, variables) {
  var operationDescriptor = RelayRuntime.createOperationDescriptor(User_getById_Query_graphql.node, User_getById_Query_graphql.Internal.convertVariables(variables));
  return environment.retain(operationDescriptor);
}

var GetQueryById = {
  Operation: undefined,
  Types: undefined,
  use: use$1,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded,
  retain: retain
};

function use$2(variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  var data = ReactRelay.useLazyLoadQuery(User_getByUsername_Query_graphql.node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(User_getByUsername_Query_graphql.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return RescriptRelay_Internal.internal_useConvertedValue(User_getByUsername_Query_graphql.Internal.convertResponse, data);
}

function useLoader$1(param) {
  var match = ReactRelay.useQueryLoader(User_getByUsername_Query_graphql.node);
  var loadQueryFn = match[1];
  var loadQuery = React.useMemo((function () {
          return function (param, param$1, param$2, param$3) {
            return Curry._2(loadQueryFn, User_getByUsername_Query_graphql.Internal.convertVariables(param), {
                        fetchPolicy: param$1,
                        networkCacheConfig: param$2
                      });
          };
        }), [loadQueryFn]);
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch$1(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  ReactRelay.fetchQuery(environment, User_getByUsername_Query_graphql.node, User_getByUsername_Query_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            Curry._1(onResult, {
                  TAG: /* Ok */0,
                  _0: User_getByUsername_Query_graphql.Internal.convertResponse(res)
                });
          }),
        error: (function (err) {
            Curry._1(onResult, {
                  TAG: /* Error */1,
                  _0: err
                });
          })
      });
}

function fetchPromised$1(environment, variables, networkCacheConfig, fetchPolicy, param) {
  var __x = ReactRelay.fetchQuery(environment, User_getByUsername_Query_graphql.node, User_getByUsername_Query_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).toPromise();
  return Js_promise.then_((function (res) {
                return Promise.resolve(User_getByUsername_Query_graphql.Internal.convertResponse(res));
              }), __x);
}

function usePreloaded$1(queryRef, param) {
  var data = ReactRelay.usePreloadedQuery(User_getByUsername_Query_graphql.node, queryRef);
  return RescriptRelay_Internal.internal_useConvertedValue(User_getByUsername_Query_graphql.Internal.convertResponse, data);
}

function retain$1(environment, variables) {
  var operationDescriptor = RelayRuntime.createOperationDescriptor(User_getByUsername_Query_graphql.node, User_getByUsername_Query_graphql.Internal.convertVariables(variables));
  return environment.retain(operationDescriptor);
}

var GetQueryByUsername = {
  Operation: undefined,
  Types: undefined,
  use: use$2,
  useLoader: useLoader$1,
  $$fetch: $$fetch$1,
  fetchPromised: fetchPromised$1,
  usePreloaded: usePreloaded$1,
  retain: retain$1
};

function use$3(variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  var data = ReactRelay.useLazyLoadQuery(User_getLatest_Query_graphql.node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(User_getLatest_Query_graphql.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return RescriptRelay_Internal.internal_useConvertedValue(User_getLatest_Query_graphql.Internal.convertResponse, data);
}

function useLoader$2(param) {
  var match = ReactRelay.useQueryLoader(User_getLatest_Query_graphql.node);
  var loadQueryFn = match[1];
  var loadQuery = React.useMemo((function () {
          return function (param, param$1, param$2, param$3) {
            return Curry._2(loadQueryFn, User_getLatest_Query_graphql.Internal.convertVariables(param), {
                        fetchPolicy: param$1,
                        networkCacheConfig: param$2
                      });
          };
        }), [loadQueryFn]);
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch$2(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  ReactRelay.fetchQuery(environment, User_getLatest_Query_graphql.node, User_getLatest_Query_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            Curry._1(onResult, {
                  TAG: /* Ok */0,
                  _0: User_getLatest_Query_graphql.Internal.convertResponse(res)
                });
          }),
        error: (function (err) {
            Curry._1(onResult, {
                  TAG: /* Error */1,
                  _0: err
                });
          })
      });
}

function fetchPromised$2(environment, variables, networkCacheConfig, fetchPolicy, param) {
  var __x = ReactRelay.fetchQuery(environment, User_getLatest_Query_graphql.node, User_getLatest_Query_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).toPromise();
  return Js_promise.then_((function (res) {
                return Promise.resolve(User_getLatest_Query_graphql.Internal.convertResponse(res));
              }), __x);
}

function usePreloaded$2(queryRef, param) {
  var data = ReactRelay.usePreloadedQuery(User_getLatest_Query_graphql.node, queryRef);
  return RescriptRelay_Internal.internal_useConvertedValue(User_getLatest_Query_graphql.Internal.convertResponse, data);
}

function retain$2(environment, variables) {
  var operationDescriptor = RelayRuntime.createOperationDescriptor(User_getLatest_Query_graphql.node, User_getLatest_Query_graphql.Internal.convertVariables(variables));
  return environment.retain(operationDescriptor);
}

var GetQueryLatest = {
  Operation: undefined,
  Types: undefined,
  use: use$3,
  useLoader: useLoader$2,
  $$fetch: $$fetch$2,
  fetchPromised: fetchPromised$2,
  usePreloaded: usePreloaded$2,
  retain: retain$2
};

function useUserAttributes(userResponse) {
  var userData = Belt_Option.flatMap(userResponse.usersPermissionsUser, (function (response) {
          return response.data;
        }));
  var userFrags = React.useMemo((function () {
          return Belt_Option.map(userData, (function (data) {
                        return readInline(data.fragmentRefs);
                      }));
        }), [userData]);
  return Belt_Option.flatMap(userFrags, (function (data) {
                return data.attributes;
              }));
}

function useRedirectNewUser(userQueryRef) {
  var router = Router.useRouter();
  var session = React$1.useSession();
  var sessionStatus = session.status;
  var userResponse = usePreloaded(userQueryRef, undefined);
  var userAttributes = useUserAttributes(userResponse);
  var isAutoUsername = Belt_Option.getWithDefault(Belt_Option.map(userAttributes, (function (user) {
                return user.username;
              })), "").startsWith(usernameDefaultPrefix);
  React.useEffect((function () {
          if (!(router.isReady && sessionStatus === "authenticated" && isAutoUsername && router.pathname !== "/account/edit" && router.pathname !== "/auth/login")) {
            return ;
          }
          var timeout = setTimeout((function (param) {
                  console.warn("new user detected, redirecting to profile edition", sessionStatus, isAutoUsername, router.pathname);
                  router.push("/account/edit?next=" + encodeURIComponent(router.asPath));
                }), 10);
          return (function (param) {
                    clearTimeout(timeout);
                  });
        }), [
        router,
        sessionStatus,
        isAutoUsername
      ]);
}

function useOptionalUserQueryRef(param) {
  var session = React$1.useSession();
  var match = useLoader(undefined);
  var disposeQuery = match[2];
  var loadQuery = match[1];
  var uuid = Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.nullable_to_opt(session.data), (function (data) {
              return data.user;
            })), (function (user) {
          return Caml_option.nullable_to_opt(user.id);
        }));
  var refreshUserQuery = React.useCallback((function (param) {
          Belt_Option.map(uuid, (function (uuid) {
                  Curry._4(loadQuery, {
                        uuid: uuid
                      }, /* NetworkOnly */3, undefined, undefined);
                }));
        }), [
        uuid,
        loadQuery
      ]);
  React.useEffect((function () {
          Curry._1(refreshUserQuery, undefined);
          return (function (param) {
                    Curry._1(disposeQuery, undefined);
                  });
        }), [
        refreshUserQuery,
        disposeQuery
      ]);
  return [
          match[0],
          refreshUserQuery
        ];
}

function getFormatOrFallback(formats, format, fallbackUrl) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Belt_Option.map(formats, (function (f) {
                            return f;
                          })), format), (function (s) {
                    return Backend.url(s.url);
                  })), Backend.url(fallbackUrl));
}

function getProfilePicture(formatOpt, user) {
  var format = formatOpt !== undefined ? formatOpt : (function (f) {
        return f.small;
      });
  return Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(user.profilePicture, (function (p) {
                        return p.data;
                      })), (function (d) {
                    return d.attributes;
                  })), (function (a) {
                return getFormatOrFallback(a.formats, format, a.url);
              }));
}

export {
  usernameDefaultPrefix ,
  username ,
  UserFragment ,
  GetQueryById ,
  GetQueryByUsername ,
  GetQueryLatest ,
  useUserAttributes ,
  useRedirectNewUser ,
  useOptionalUserQueryRef ,
  getFormatOrFallback ,
  getProfilePicture ,
}
/* react Not a pure module */
