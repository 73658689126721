// Generated by ReScript, PLEASE EDIT WITH CARE

import * as T from "../T.bs.js";
import * as Auth from "../Auth.bs.js";
import * as Line from "./shareable/Line.bs.js";
import * as User from "../User.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Avatar from "./Avatar.bs.js";
import * as SVGGear from "../svgs/components/SVGGear.bs.js";
import * as LinkView from "./shareable/LinkView.bs.js";
import * as NextIntl from "next-intl";
import * as LinkButton from "./shareable/LinkButton.bs.js";
import * as SVGJokerie from "../svgs/components/SVGJokerie.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Router from "next/router";
import * as ReactNative from "react-native";
import * as ViewContained from "./shareable/ViewContained.bs.js";
import * as SVGXmarkCircle from "../svgs/components/SVGXmarkCircle.bs.js";
import * as React$1 from "next-auth/react";
import * as Style$ReactNative from "rescript-react-native/src/apis/Style.bs.js";
import * as Font$ReactMultiversal from "react-multiversal/src/Font.bs.js";
import * as SVGPersonCropCircleFill from "../svgs/components/SVGPersonCropCircleFill.bs.js";
import * as Spacer$ReactMultiversal from "react-multiversal/src/Spacer.bs.js";
import * as Predefined$ReactMultiversal from "react-multiversal/src/Predefined.bs.js";
import * as SpacedView$ReactMultiversal from "react-multiversal/src/SpacedView.bs.js";

function WebsiteHeader$WebsiteHeaderUser(Props) {
  var userQueryRef = Props.userQueryRef;
  var theme = Curry._2(T.useTheme, undefined, undefined);
  var t = NextIntl.useTranslations();
  var userResponse = User.GetQueryById.usePreloaded(userQueryRef, undefined);
  var userAttributes = User.useUserAttributes(userResponse);
  var profilePicture = Belt_Option.flatMap(userAttributes, (function (user) {
          return User.getProfilePicture((function (f) {
                        return f.xsmall;
                      }), user);
        }));
  var match = React.useState(function () {
        return false;
      });
  var isOpen_set = match[1];
  return React.createElement(ReactNative.View, {
              children: null
            }, React.createElement(ReactNative.Pressable, {
                  children: (function (param) {
                      var tmp = {
                        size: 24
                      };
                      if (profilePicture !== undefined) {
                        tmp.uri = Caml_option.valFromOption(profilePicture);
                      }
                      return React.createElement(Avatar.make, tmp);
                    }),
                  onPress: (function (param) {
                      Curry._1(isOpen_set, (function (v) {
                              return !v;
                            }));
                    })
                }), match[0] ? React.createElement(ReactNative.View, {
                    style: [
                      theme.styles.back,
                      {
                        borderColor: theme.colors.systemGray,
                        borderRadius: 3,
                        borderWidth: 0.5,
                        position: "absolute",
                        right: -24,
                        top: 34,
                        width: 175
                      }
                    ],
                    children: null
                  }, React.createElement(Spacer$ReactMultiversal.make, {
                        size: /* XXS */6
                      }), React.createElement(LinkView.make, {
                        href: "/@/" + Belt_Option.getWithDefault(Belt_Option.map(userAttributes, (function (attr) {
                                    return attr.username;
                                  })), ""),
                        children: React.createElement(SpacedView$ReactMultiversal.make, {
                              vertical: /* XXS */6,
                              horizontal: /* XS */5,
                              style: [
                                Predefined$ReactMultiversal.styles.row,
                                Predefined$ReactMultiversal.styles.alignCenter
                              ],
                              children: null
                            }, React.createElement(SVGPersonCropCircleFill.make, {
                                  width: 16,
                                  height: 16,
                                  fill: theme.colors.text
                                }), React.createElement(Spacer$ReactMultiversal.make, {
                                  size: /* XS */5
                                }), React.createElement(ReactNative.Text, {
                                  children: t("Header-Profile"),
                                  style: [
                                    Font$ReactMultiversal.ios.subhead,
                                    Font$ReactMultiversal.weight[400],
                                    theme.styles.text
                                  ]
                                }))
                      }), React.createElement(LinkView.make, {
                        href: "/account/edit",
                        children: React.createElement(SpacedView$ReactMultiversal.make, {
                              vertical: /* XXS */6,
                              horizontal: /* XS */5,
                              style: [
                                Predefined$ReactMultiversal.styles.row,
                                Predefined$ReactMultiversal.styles.alignCenter
                              ],
                              children: null
                            }, React.createElement(SVGGear.make, {
                                  width: 16,
                                  height: 16,
                                  fill: theme.colors.text
                                }), React.createElement(Spacer$ReactMultiversal.make, {
                                  size: /* XS */5
                                }), React.createElement(ReactNative.Text, {
                                  children: t("Header-Settings"),
                                  style: [
                                    Font$ReactMultiversal.ios.subhead,
                                    Font$ReactMultiversal.weight[400],
                                    theme.styles.text
                                  ]
                                }))
                      }), React.createElement(Spacer$ReactMultiversal.make, {
                        size: /* XXS */6
                      }), React.createElement(Line.make, {
                        width: Style$ReactNative.pct(100),
                        color: theme.colors.systemGray5
                      }), React.createElement(Spacer$ReactMultiversal.make, {
                        size: /* XXS */6
                      }), React.createElement(ReactNative.TouchableOpacity, {
                        onPress: (function (param) {
                            Auth.logout(undefined);
                          }),
                        children: React.createElement(SpacedView$ReactMultiversal.make, {
                              vertical: /* XXS */6,
                              horizontal: /* XS */5,
                              style: [
                                Predefined$ReactMultiversal.styles.row,
                                Predefined$ReactMultiversal.styles.alignCenter
                              ],
                              children: null
                            }, React.createElement(SVGXmarkCircle.make, {
                                  width: 16,
                                  height: 16,
                                  fill: theme.colors.textLight2
                                }), React.createElement(Spacer$ReactMultiversal.make, {
                                  size: /* XS */5
                                }), React.createElement(ReactNative.Text, {
                                  children: t("Header-Logout"),
                                  style: [
                                    Font$ReactMultiversal.ios.subhead,
                                    Font$ReactMultiversal.weight[400],
                                    theme.styles.text
                                  ]
                                }))
                      }), React.createElement(Spacer$ReactMultiversal.make, {
                        size: /* XXS */6
                      })) : null);
}

var WebsiteHeaderUser = {
  make: WebsiteHeader$WebsiteHeaderUser
};

function WebsiteHeader(Props) {
  var userOptionalQueryRef = Props.userOptionalQueryRef;
  var noCreateOpt = Props.noCreate;
  var noLoginOpt = Props.noLogin;
  var noCreate = noCreateOpt !== undefined ? noCreateOpt : false;
  var noLogin = noLoginOpt !== undefined ? noLoginOpt : false;
  var theme = Curry._2(T.useTheme, undefined, undefined);
  var t = NextIntl.useTranslations();
  var router = Router.useRouter();
  var session = React$1.useSession();
  var match = session.status;
  return React.createElement(ReactNative.View, {
              style: [
                theme.styles.backDark,
                {
                  zIndex: 1
                }
              ],
              children: React.createElement(ViewContained.make, {
                    children: React.createElement(SpacedView$ReactMultiversal.make, {
                          vertical: /* XS */5,
                          horizontal: /* XS */5,
                          style: Predefined$ReactMultiversal.styles.rowSpaceBetween,
                          children: null
                        }, React.createElement(LinkView.make, {
                              href: "/",
                              style: Predefined$ReactMultiversal.styles.flexShrink,
                              children: React.createElement(SVGJokerie.make, {
                                    height: 30,
                                    fill: theme.colors.main,
                                    style: {
                                      maxWidth: Style$ReactNative.pct(100),
                                      minWidth: 30
                                    }
                                  })
                            }), React.createElement(ReactNative.View, {
                              style: [
                                Predefined$ReactMultiversal.styles.flexShrink,
                                Predefined$ReactMultiversal.styles.rowWrapCenter
                              ],
                              children: match === "loading" ? React.createElement(ReactNative.ActivityIndicator, {
                                      size: "small"
                                    }) : (
                                  match === "unauthenticated" ? React.createElement(React.Fragment, undefined, noCreate ? null : React.createElement(LinkView.make, {
                                                href: "/auth/signup",
                                                children: React.createElement(SpacedView$ReactMultiversal.make, {
                                                      vertical: /* XXS */6,
                                                      horizontal: /* XS */5,
                                                      children: React.createElement(ReactNative.Text, {
                                                            children: t("Header-SignUp"),
                                                            style: [
                                                              Font$ReactMultiversal.ios.footnote,
                                                              Font$ReactMultiversal.weight[600],
                                                              theme.styles.text,
                                                              {
                                                                textAlign: "center"
                                                              }
                                                            ]
                                                          })
                                                    })
                                              }), noLogin ? null : React.createElement(LinkButton.make, {
                                                href: "/auth/login?next=" + encodeURIComponent(router.asPath),
                                                children: t("Header-SignIn"),
                                                color2: theme.colors.main,
                                                horizontalSpace: /* XS */5,
                                                mode: /* Outlined */2,
                                                textStyle: [
                                                  Font$ReactMultiversal.ios.footnote,
                                                  Font$ReactMultiversal.weight[600],
                                                  theme.styles.textOnMain,
                                                  {
                                                    textAlign: "center"
                                                  }
                                                ],
                                                verticalSpace: /* XXS */6
                                              })) : React.createElement(React.Fragment, undefined, userOptionalQueryRef !== undefined ? React.createElement(WebsiteHeader$WebsiteHeaderUser, {
                                                userQueryRef: Caml_option.valFromOption(userOptionalQueryRef)
                                              }) : null)
                                )
                            }))
                  })
            });
}

var make = WebsiteHeader;

var $$default = WebsiteHeader;

export {
  WebsiteHeaderUser ,
  make ,
  $$default ,
  $$default as default,
}
/* T Not a pure module */
