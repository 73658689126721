// Generated by ReScript, PLEASE EDIT WITH CARE


var FeatureVisibility = {};

var Annotation = {};

var MarkerAnnotation = {};

var scriptUrl = "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js";

export {
  scriptUrl ,
  FeatureVisibility ,
  Annotation ,
  MarkerAnnotation ,
}
/* No side effect */
