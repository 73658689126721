// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as LinkView from "./LinkView.bs.js";
import * as ButtonView from "./ButtonView.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function LinkButton(Props) {
  var href = Props.href;
  var accessibilityLabel = Props.accessibilityLabel;
  var indicator = Props.indicator;
  var children = Props.children;
  var color = Props.color;
  var color2 = Props.color2;
  var horizontalSpace = Props.horizontalSpace;
  var mode = Props.mode;
  var round = Props.round;
  var style = Props.style;
  var textStyle = Props.textStyle;
  var verticalSpace = Props.verticalSpace;
  var tmp = {
    children: children
  };
  if (accessibilityLabel !== undefined) {
    tmp.accessibilityLabel = Caml_option.valFromOption(accessibilityLabel);
  }
  if (indicator !== undefined) {
    tmp.indicator = Caml_option.valFromOption(indicator);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (color2 !== undefined) {
    tmp.color2 = Caml_option.valFromOption(color2);
  }
  if (horizontalSpace !== undefined) {
    tmp.horizontalSpace = Caml_option.valFromOption(horizontalSpace);
  }
  if (mode !== undefined) {
    tmp.mode = Caml_option.valFromOption(mode);
  }
  if (round !== undefined) {
    tmp.round = Caml_option.valFromOption(round);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (textStyle !== undefined) {
    tmp.textStyle = Caml_option.valFromOption(textStyle);
  }
  if (verticalSpace !== undefined) {
    tmp.verticalSpace = Caml_option.valFromOption(verticalSpace);
  }
  return React.createElement(LinkView.make, {
              href: href,
              children: React.createElement(ButtonView.make, tmp)
            });
}

var make = LinkButton;

export {
  make ,
}
/* react Not a pure module */
