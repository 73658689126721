// Generated by ReScript, PLEASE EDIT WITH CARE

import * as T from "../T.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactNative from "react-native";
import * as SVGPersonCropCircleFill from "../svgs/components/SVGPersonCropCircleFill.bs.js";

function border(size) {
  return React.createElement(ReactNative.View, {
              style: [
                ReactNative.StyleSheet.absoluteFill,
                {
                  borderColor: "rgba(0,0,0,0.1)",
                  borderRadius: size,
                  borderWidth: 1
                }
              ]
            });
}

function Avatar(Props) {
  var uri = Props.uri;
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  var theme = Curry._2(T.useTheme, undefined, undefined);
  return React.createElement(ReactNative.View, {
              pointerEvents: "none",
              style: {
                alignSelf: "center"
              },
              children: null
            }, Belt_Option.getWithDefault(Belt_Option.map(uri, (function (uri) {
                        return React.createElement(ReactNative.Image, {
                                    source: {
                                      uri: uri,
                                      width: size,
                                      height: size
                                    },
                                    style: {
                                      borderRadius: size
                                    }
                                  });
                      })), React.createElement(SVGPersonCropCircleFill.make, {
                      width: size,
                      height: size,
                      fill: theme.colors.main
                    })), border(size));
}

var minUploadSize = 256;

var make = Avatar;

export {
  minUploadSize ,
  border ,
  make ,
}
/* T Not a pure module */
