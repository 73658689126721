// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";
import * as SpacedView$ReactMultiversal from "react-multiversal/src/SpacedView.bs.js";

var styles = ReactNative.StyleSheet.create({
      container: {
        borderRadius: 6,
        borderWidth: 1,
        alignItems: "center",
        justifyContent: "center"
      },
      rounded: {
        borderRadius: 100
      },
      indicatorContainer: {
        alignItems: "center",
        bottom: 0,
        justifyContent: "center",
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: 1
      }
    });

function ButtonView(Props) {
  var accessibilityLabel = Props.accessibilityLabel;
  var indicatorOpt = Props.indicator;
  var children = Props.children;
  var c1Opt = Props.color;
  var c2Opt = Props.color2;
  var horizontalOpt = Props.horizontalSpace;
  var modeOpt = Props.mode;
  var roundOpt = Props.round;
  var s = Props.style;
  var styleText = Props.textStyle;
  var verticalOpt = Props.verticalSpace;
  var indicator = indicatorOpt !== undefined ? indicatorOpt : "none";
  var c1 = c1Opt !== undefined ? c1Opt : "black";
  var c2 = c2Opt !== undefined ? c2Opt : "white";
  var horizontal = horizontalOpt !== undefined ? horizontalOpt : /* M */3;
  var mode = modeOpt !== undefined ? modeOpt : /* Contained */0;
  var round = roundOpt !== undefined ? roundOpt : false;
  var vertical = verticalOpt !== undefined ? verticalOpt : /* S */4;
  var tmp;
  switch (mode) {
    case /* Contained */0 :
        tmp = [
          styles.container,
          {
            backgroundColor: c1,
            borderColor: c1
          }
        ];
        break;
    case /* ContainedInverted */1 :
        tmp = [
          styles.container,
          {
            backgroundColor: c1,
            borderColor: "transparent"
          }
        ];
        break;
    case /* Outlined */2 :
        tmp = [
          styles.container,
          {
            backgroundColor: c2,
            borderColor: c1
          }
        ];
        break;
    
  }
  var tmp$1;
  if (indicator === "activity") {
    tmp$1 = React.createElement(ReactNative.View, {
          style: styles.indicatorContainer,
          children: React.createElement(ReactNative.ActivityIndicator, {
                color: mode !== 0 ? c1 : c2,
                size: "small"
              })
        });
  } else if (indicator === "success") {
    var tmp$2;
    switch (mode) {
      case /* Contained */0 :
          tmp$2 = {
            color: c2
          };
          break;
      case /* ContainedInverted */1 :
      case /* Outlined */2 :
          tmp$2 = {
            color: c1
          };
          break;
      
    }
    tmp$1 = React.createElement(ReactNative.View, {
          style: styles.indicatorContainer,
          children: React.createElement(ReactNative.Text, {
                children: "✔",
                style: tmp$2
              })
        });
  } else {
    tmp$1 = null;
  }
  var tmp$3;
  switch (mode) {
    case /* Contained */0 :
        tmp$3 = {
          color: c2
        };
        break;
    case /* ContainedInverted */1 :
    case /* Outlined */2 :
        tmp$3 = {
          color: c1
        };
        break;
    
  }
  var tmp$4 = {
    style: [
      Caml_option.some(tmp),
      round ? Caml_option.some(styles.rounded) : undefined,
      s
    ],
    children: React.createElement(SpacedView$ReactMultiversal.make, {
          vertical: vertical,
          horizontal: horizontal,
          children: null
        }, tmp$1, React.createElement(ReactNative.Text, {
              children: children,
              style: [
                Caml_option.some(tmp$3),
                {
                  opacity: indicator !== "none" ? 0 : 1
                },
                styleText
              ]
            }))
  };
  if (accessibilityLabel !== undefined) {
    tmp$4.accessibilityLabel = accessibilityLabel;
  }
  return React.createElement(ReactNative.View, tmp$4);
}

var make = ButtonView;

export {
  styles ,
  make ,
}
/* styles Not a pure module */
