// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as NextAuth from "./bindings/NextAuth.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as React from "next-auth/react";

var Session = {};

var include = NextAuth.MakeSession(Session);

function login(email, password, router, onError) {
  React.signIn("credentials", {
          email: email,
          password: password,
          redirect: false
        }).then(function (result) {
        var nextUrl = Belt_Option.getWithDefault(Js_dict.get(router.query, "next"), "/");
        console.log("login", result, "redirecting to", nextUrl);
        if (result.ok) {
          setTimeout((function (param) {
                  router.push(nextUrl);
                }), 200);
        } else {
          Curry._1(onError, result);
        }
        return Promise.resolve(undefined);
      });
}

function logout(prim) {
  React.signOut();
}

var SessionProvider = include.SessionProvider;

export {
  Session ,
  SessionProvider ,
  login ,
  logout ,
}
/* include Not a pure module */
